import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header, Footer } from './components/common';
import { Home, Portfolio, Shop, OverOns, Contact, NoPage, Product } from './components/pages';

import './App.css';
import './fa/css/all.min.css';

function App() {
  return (
    <div className="wp_web">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header title='Shop'/>}>
            <Route index element={<Home />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="shop" element={<Shop />} />
            <Route path="product/:productslug" element={<Product />} />
            <Route path="over-ons" element={<OverOns />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
