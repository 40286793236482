// Imports
import React from 'react';
import { Navbar } from '../../common' ;
import logo from '../../.././sitework_logo_black.svg';
import { Outlet } from "react-router-dom";

import './Header.css';

// Functions
function Header(props) {
    let pageTitle = props.title;
    return (
        <>
            <header>
                <div className="container mx-auto flex justify-between items-center px-4 lg:px-0 py-6">
                    <div className='left'>
                        <img src={logo} alt="Sitework B.V. logo" />
                    </div>
                    <div className='right'>
                        <Navbar />
                    </div>
                </div>

                <section className='hero mb-8 mt-2'>
                    <div className='container mx-auto px-8 py-4 rounded-lg'>
                        <h1 className='text-white'>{pageTitle}</h1>
                    </div>
                </section>
            </header>
            <Outlet />
        </>
    );
}

export default Header; // Export the Footer component