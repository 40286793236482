import React from 'react';
import { Link } from "react-router-dom";


import './Navbar.css'

function Navbar () {

  return (
    <section className="navbar flex items-center gap-4 text-xl">
      <Link to="/" className="navbar-item">Home</Link>
      <Link to="/portfolio" className="navbar-item">Portfolio</Link>
      <Link to="/shop" className="navbar-item">Shop</Link>
      <Link to="/over-ons" className="navbar-item">Over ons</Link>
      <Link to="/contact" className="navbar-item">Contact</Link>
  </section>
  )

}

export default Navbar;