import React from 'react';

// Functions
function Home() {
    return (
        <main>
            <div className="container mx-auto">
                <h1>Home</h1>
                <div></div>
            </div>
        </main>
    );
}

export default Home; // Export the Footer component