import React, { useEffect, useState } from 'react';
import placeholder from '../../.././noimg.jpeg';
import { consumer_key, consumer_secret } from '../../.././settings';

import './Shop.css';

function Shop() {
    const [posts, setPosts] = useState([]);
    const [itemAdded, setItemAdded] = useState({ id: null, added: false });

    useEffect(() => {
        async function loadPosts() {
            try {
                const response = await fetch('https://stagewp.sitework.link/server/wp-json/wp/v2/product?_embed');
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                const posts = await response.json();
                setPosts(posts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }
        loadPosts();
    }, []);

    useEffect(() => {
        async function postJSON(data) {
            try {
                const authResponse = await fetch("https://stagewp.sitework.link/server/wp-json/wc-auth/v1/authorize", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        consumer_key: consumer_key,
                        consumer_secret: consumer_secret
                    }),
                });
                if (!authResponse.ok) {
                    throw new Error('Authentication failed');
                }

                const authData = await authResponse.json();
                const accessToken = authData.token;

                const response = await fetch("https://stagewp.sitework.link/server/wp-json/wc/store/v1/cart/add-item?consumer_key="+consumer_key+"&consumer_secret="+consumer_secret+"", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(data),
                });
                if (!response.ok) {
                    throw new Error('Failed to add item to cart');
                }
                const result = await response.json();
                console.log("Success:", result);
            } catch (error) {
                console.error("Error:", error);
            }
            setItemAdded({ id: null, added: false })
        }

        if (itemAdded.added && itemAdded.id !== null) {
            alert("Item met id: " + itemAdded.id + ". Toegevoegd aan winkelwagen!");
            console.info(itemAdded);
            let post_data = {
                data: {
                    id: itemAdded.id,
                    quantity: 1,
                }
            };
            postJSON(post_data);
        }
    }, [itemAdded]);

    return (
        <main>
            <div className="container mx-auto">
                <div className='WC-products grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8'>
                    {posts.map((post, index) => (
                        (post.status === "publish") ? (
                            <div id={'post-' + post.id} key={index} className='shadow-md rounded-md'>
                                <div className='rounded-t-md overflow-hidden'>
                                    <img src={post._embedded['wp:featuredmedia']['0'].media_details.sizes.woocommerce_single.source_url || placeholder} alt={post.title.rendered} />
                                </div>
                                <div className='WC-info min-h-[200px] relative pb-16 px-4 py-2'>
                                    <h3>{post.title.rendered}</h3>
                                    <p className='description' dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
                                    <div className='buttons flex items-center justify-between'>
                                        <a href={"https://stagewp.sitework.link/product/" + post.slug} className='btn primary read-more'>Lees meer</a>
                                        <span className='btn secondary add-to-cart cursor-pointer' onClick={() => setItemAdded({ id: post.id, added: true })}><i className='fas fa-shopping-cart'></i></span>
                                    </div>
                                </div>
                            </div>
                        ) : (null)
                    ))}
                </div>
            </div>
        </main>
    );
}

export default Shop;
