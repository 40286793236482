import React from 'react';
import { useParams, Link } from  "react-router-dom";

import './Product.css';

// Functions
function Product() {
    var  productslug = useParams();
    console.log(productslug.productslug); 
    var productBread = productslug.productslug.replace(/-/g, " ");

    return (
        <main>
            <div className='container mx-auto breadcrumps'>
                <ul>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/shop'>Shop</Link></li>
                    <li><span>{productBread}</span></li>
                </ul>
            </div>
            <div className="container mx-auto">
                <h1>Product</h1>
                <div></div>
            </div>
        </main>
    );
}

export default Product; // Export the Footer component